import React, { SFC } from "react"
import Helmet from "react-helmet";

import { graphql } from "gatsby"

import DefaultPage from "../templates/default-page";
import NewsAndUpdates from "../components/news-and-updates";
import Footer from "../components/footer";

class IndexPage extends React.Component<IndexPageQuery, {}> {
    constructor(props: any) {
        super(props)
    }

    state = {
    }

    render() {
        const NewsItems = this.props.data.news.edges
        .filter(p => !p.node.frontmatter.draft)
        .slice(0, 6)

        const copy = this.props.data.homepage.edges[0].node.frontmatter

        return (
            <DefaultPage isIndex={false} isShowLetterbox={false} onLetterboxClose={() => {}} letterboxItem={[]}>
                <Helmet>
                    <title>Frostfall - News Archive</title>
                    <meta name="description" content="Frostfall - News Archive" />
                </Helmet>
    
    
                <NewsAndUpdates sectionHeading={copy.newsHeading} archiveButtonText={copy.newsArchiveButtonText} newsItems={NewsItems} isArchive={true}/>
                <Footer />
            </DefaultPage>
        )
    }
}


export default IndexPage;

export const pageQuery = graphql`
  query {
    homepage: allMarkdownRemark(filter: {fields:{sourceName: {eq: "homepage"}}}) {
      edges {
        node {
          frontmatter {
            ctaHeading
            ctaSubtitle
            ctaButtonText
            newsHeading
            newsArchiveButtonText
            mediaHeading
            mediaArchiveButtonText
            socialHeading
            socialSubtitle
            downloadHeading
          }
        }
      }
    },
    news: allMarkdownRemark(filter: {fields:{sourceName: {eq: "news"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            draft
            title
            image
          }
        }
      }
    }
  }
`