import React from "react"
import NewsCard from "./news-card";

interface INewsAndUpdatesProps {
    newsItems: Array<GraphEdge<NewsPost>>
    isArchive?: boolean
    sectionHeading: string
    archiveButtonText: string
}

class NewsAndUpdates extends React.Component<INewsAndUpdatesProps>{
    render() {
        const title = this.props.isArchive ? this.props.sectionHeading + " Archive" : this.props.sectionHeading

        return (
            <section className="c-news-and-updates o-section" id="news">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <h1 className="o-heading">
                                <span>{title}</span>
                            </h1>
                        </div>
                        {this.props.isArchive ? null : <div className="c-news-and-updates__desktop-archive col-lg-4"><a className="o-button--archive" href="/newsArchive">{this.props.archiveButtonText}</a></div>}
                    </div>
                </div>
                <div className="container-fluid">
                    <ul className="o-card__container row">
                        {this.props.newsItems.map((post) => <NewsCard post={post} key={post.node.id}/>)}
                    </ul>
                    {this.props.isArchive ? null : <div className="c-news-and-updates__mobile-archive"><a className="o-button--archive" href="/newsArchive">{this.props.archiveButtonText}</a></div>}
                </div>
            </section>
        )
    }
}

export default NewsAndUpdates