import React from "react"
import Image from './image/cmsImageSmall'

interface INewsCardProps {
    post: GraphEdge<NewsPost>
}

class NewsCard extends React.Component<INewsCardProps>{
    render() {
        const post = this.props.post.node
        const tags = post.frontmatter.tags || []
        return (
            <li className="o-card col-sm-6 col-xl-4" key={post.id}>
                <a className="o-card__link" href={post.frontmatter.path}>
                    <div className="o-card__image-container">
                        <div className="o-card__image-inner-container">
                            <Image className="o-card__image"
                                alt={post.frontmatter.title}
                                src={post.frontmatter.image}/>
                        </div>
                        <ul className="o-tags">
                            {tags.map((tag) => (
                                <li className="o-tags__tag" key={tag}>{tag}</li>
                            ))}
                        </ul>
                    </div>
                    <h2 className="o-card__heading">{post.frontmatter.title}</h2>
                    <h3 className="o-card__subheading">{post.frontmatter.date}</h3>
                </a>
            </li>
        )
    }
}

export default NewsCard